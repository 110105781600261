import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, Col, Row } from 'reactstrap'
import { cms } from '../../helper'
import { request } from '../../Redux/Sagas/requests/api'
import { primary_color } from '../../Helper/uiHelper'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import './Scss/oldBooking.scss'
import CMSWidgetModal from '../CMSWidget/cmsWidgetModal'
import CMSSearchResultx from '../CMSWidget/SearchResult/CMSSearchResult'
import CMSOldBooking from '../CMSWidget/SearchResult/CMSOldWidget'
const default_image = 'https://test-api.ionicbox.com/storage/files/1/GyexiOYukHj8.png'

const DUMMY_DATA = [
  {
    title: 'Reservation Id',
    value: '12342',
  },
  {
    title: 'Reservation Id',
    value: '12342',
  },
  {
    title: 'Reservation Id',
    value: '12342',
  },
  {
    title: 'Reservation Id',
    value: '12342',
  },
  {
    title: 'Reservation Id',
    value: '12342',
  },
]

const getBookingDetails = (fields, data = {}) => {
  const details = fields?.map((field) => {
    let obj = {
      ...field,
    }
    obj['value'] = data[`${field?.field_id}_label`] || data[field?.field_id]
    return obj
  })
  return details
}

const OldBooking = ({ data, searchResults, activePage, widget_uuid }) => {
  const [fields, updateFields] = useState()
  const [bookingData, setBookingData] = useState()
  const publicUser = useSelector((state) => state.User.publicUser)
  const history = useHistory()
  const location = useLocation()
  const isDesktop = useMediaQuery('(min-width:768px)')
  const editMode = useSelector((state) => state.CMS.editMode)

  const widgetRef = useRef(null)
  const sectionRef = useRef(null)

  const [modalPosition, setModalPosition] = useState()
  const [modalPositionSideBar, setModalPositionSideBar] = useState()

  const handleSectionClick = () => {
    if (!editMode) return

    if (widgetRef.current) {
      const rect = widgetRef.current.getBoundingClientRect()
      setModalPosition({
        top: 200,
        // left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '60vw',
      })
    }
  }

  const handleSectionSidebarClick = () => {
    if (!editMode) return

    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPositionSideBar({
        top: 200,
        // left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '60vw',
      })
    }
  }
   const onCloseModal = () => {
     let element = widgetRef?.current
     if (element) {
       element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
     }
     setModalPosition(null)
   }

  const searchParams = new URLSearchParams(location.search)
  useEffect(() => {
    if (!publicUser?.id) return
    const fields = []
    data?.form?.[0]?.component_call_fields?.map((field) => {
      fields?.push(field?.field_id)
    })
    request('get-old-booking', 'POST', {
      user_id: publicUser?.id,
      fields,
    }).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setBookingData(res?.data)
      }
    })
    const new_fields = []
    data?.form?.[0]?.component_call_fields?.map((field) => {
      new_fields?.push({
        field_id: field?.field_id,
        title: cms(field?.cms_key),
      })
    })
    updateFields(new_fields)
  }, [publicUser?.id])

  if (!publicUser?.email || !bookingData) return

  // Homepage :-
  if (!searchParams.get('parent_id')) {
    // if (!searchResults?.length) return

   

    return (
      <>
        <div className='mt-5 mb-5 d-flex align-items-center' style={{ paddingInline: '5rem' }}>
          <div style={{ flex: 1, paddingInline: '1rem' }}>
            <hr></hr>
          </div>
          <div>
            {' '}
            <span className='text-muted subtitle1'>or</span>
          </div>

          <div style={{ flex: 1, paddingInline: '1rem' }}>
            <hr></hr>
          </div>
        </div>{' '}
        <div className='col-md-12 ml-auto mr-auto mb-5' onClick={handleSectionClick} ref={widgetRef}>
          <div
            className='fs-4 d-flex align-items-center justify-content-between'
            style={{ margin: isDesktop ? '3rem 3rem 3rem 0.25rem' : '3rem 0.25rem' }}>
            <div dangerouslySetInnerHTML={{ __html: data?.heading }} className='content-widget'></div>
            {/* <span className='me-2' style={{ fontSize: '1.2rem', fontWeight: '500' }}>
            {data.heading}
          </span> */}
          </div>

          <div className=' mb-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
            <div className='d-flex align-items-center'>
              <div style={{ padding: '6px', borderRadius: '50%', border: '2px solid #495057' }}>
                <img src={default_image} width='50' height='50' className='me-3' style={{}} />
              </div>
              <div className='ml-3 pt-1 color-black'>
                <div className='mb-0 body1' style={isDesktop ? { fontWeight: '700' } : { fontWeight: '600' }}>
                  {bookingData.domain_name}
                </div>
                <div className='d-flex mt-2' style={{ flexWrap: 'wrap' }}>
                  <div className='d-flex flex-column  mb-2' style={{ marginRight: '3rem' }}>
                    <div className='font-weight-bold subtitle1'>Reservation ID</div>
                    <div className='text-muted subtitle1' style={{ fontWeight: '100' }}>
                      {bookingData?.booking_id}
                    </div>
                  </div>
                  <div className='d-flex flex-column  mb-2' style={{ marginRight: '3rem' }}>
                    <div className='font-weight-bold subtitle1'>Date</div>
                    <div className='text-muted subtitle1' style={{ fontWeight: '100' }}>
                      {bookingData?.start_time}-{bookingData?.end_time}
                    </div>
                  </div>
                  <div className='d-flex flex-column  mb-2' style={{ marginRight: '3rem' }}>
                    <div className='font-weight-bold subtitle1'>No of Locker</div>
                    <div className='text-muted subtitle1' style={{ fontWeight: '100' }}>
                      {bookingData?.units?.length || 0}
                    </div>
                  </div>
                </div>

                {/* <span style={{ fontSize: isDesktop ? '0.75rem' : '0.65rem' }}>{bookingData?.address_name}</span> */}
              </div>
            </div>
            <div className='d-flex justify-content-end mt-2' style={{ marginRight: isDesktop ? '3rem' : '0', marginBlock: 'auto' }}>
              <button
                className={`btn text-white subtitle1 old-booking-btn`}
                style={{ borderRadius: '30px', background: primary_color }}
                onClick={() => {
                  history.push(`?parent_id=${bookingData.domain_id}&page_id=${activePage?.child_pages[0]?.uuid}&old_booking=true`)
                }}>
                {data?.button_text}
              </button>
            </div>
          </div>
          {modalPosition && (
            <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
              <CMSSearchResultx
                data={data}
                toggleModal={() => {
                  onCloseModal()
                }}
                widget_uuid={widget_uuid}
              />
            </CMSWidgetModal>
          )}

          <div className='mt-5 mb-5' style={{ paddingInline: '5rem' }}>
            <hr></hr>
          </div>
        </div>
      </>
    )
  }

  // Sidebar
  const onBookAgain = () => {
    const link = `${window.location.pathname}?parent_id=${searchParams.get('parent_id')}&page_id=${searchParams.get('page_id')}&old_booking=true`
    history.push(link)
  }
  const details = getBookingDetails(fields, bookingData)

  console.log('old booking::::::::', data)

  const onCloseSidebarModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPositionSideBar(null)
  }

  const heading = data?.heading || '<p><b>Past Booking</b></p>'
  return (
    <div
      className='mt-2 old-booking-container'
      style={{ padding: '2.5rem 0', borderBottom: '1px solid lightgrey' }}
      onClick={handleSectionSidebarClick}
      ref={sectionRef}>
      <div className=' text-left  font-weight-bold mb-4' style={{ color: primary_color }}>
        <div
          className='body1 primary-color heading font-weight-bold'
          style={{ color: primary_color }}
          dangerouslySetInnerHTML={{ __html: heading }}></div>
      </div>
      {details?.map((item, index) => {
        return (
          <div className='mb-3' key={index}>
            <div className='font-weight-bold subtitle1'>{item?.title}</div>
            <div className='subtitle1 text-muted' style={{ fontSize: '100' }}>
              {item?.value || '-'}
            </div>
          </div>
        )
      })}

      <button
        className='btn btn-primary rounded-pill mt-2 subtitle1 w-100 font-weight-bold'
        style={{ background: primary_color }}
        onClick={() => onBookAgain()}>
        {data?.button_text || 'Book Again'}
      </button>
      {modalPositionSideBar && (
        <CMSWidgetModal sx={{ ...modalPositionSideBar }} toggleModal={() => onCloseSidebarModal()}>
          <CMSOldBooking
            data={data}
            toggleModal={() => {
              onCloseSidebarModal()
            }}
            widget_uuid={widget_uuid}
          />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default OldBooking
