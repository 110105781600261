import moment from 'moment'
import { primary_color } from '../../Helper/uiHelper'
import { getObjectDifference, tidyName } from '../../helper'

const getValue = (data) => {
  return data?.name || data?.location?.name
}

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference }) => {
  const booking_data = updated_data?.[0]
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          Customer email
        </div>
        <div className='p-4'>{booking_data?.customer_email}</div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          Address
        </div>
        <div className='p-4'>
          {booking_data?.billing_address1 +
            ' ' +
            booking_data?.billing_city +
            ' ' +
            booking_data?.billing_country +
            ' ' +
            booking_data?.billing_post_code}
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          Customer email
        </div>
        <div className='p-4'>{booking_data?.transaction_id}</div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          Lockers
        </div>
        <div className='p-4'>{updated_data?.units?.map((item)=>{
          return (
            <>
              <div className='d-flex'>
                <div style={{ width: '50%' }} className='font-weight-bold'>
                  School:
                </div>
                <div>{item?.domain?.domain_name}</div>
              </div>
              <div className='d-flex'>
                <div style={{ width: '50%' }} className='font-weight-bold'>
                  Block:
                </div>
                <div>{item?.location?.name}</div>
              </div>
              <div className='d-flex'>
                <div style={{ width: '50%' }} className='font-weight-bold'>
                  Locker Number:
                </div>
                <div>{item?.item?.item_name}</div>
              </div>
            </>
          )
        })}</div>
      </div>
    </div>
  )
}

const PurchaseCreateLog = ({ data, onExpand, expandedId }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)

  console.log('expandedsafdasf id:::::', updated_data)
  if (expandedId) {
    return <ShowDifference updated_data={updated_data} onExpand={onExpand}></ShowDifference>
  }
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span>{updated_data?.[0]?.billing_first_name}</span>
        {`has booked the locker and confirmation email sent to  `}
        <span className='font-weight-bold'>{updated_data?.[0]?.customer_email}</span>
      </div>
      {data?.updated_at && <div>{moment(data?.updated_at).format('MMM D  YYYY, hh:mm')}</div>}
    </div>
  )
}

export default PurchaseCreateLog
