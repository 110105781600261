import moment from 'moment'
import { primary_color } from '../../Helper/uiHelper'
import { getObjectDifference, tidyName } from '../../helper'
import {
  checkIsDataUpdated,
  checkIsDataUpdatedInLog,
  checkIsFieldHide,
  getFieldsAndLabelByTab,
  getTabNameForLogs,
  getValueByFieldTypeNid,
} from './logHelper'
import { get } from 'lodash'

const getDifferenceDataByKey = (key, data, isOld) => {
  switch (key) {
    case 'role':
      if (isOld) {
        return data?.obj2?.name
      } else {
        return data?.obj1?.name
      }
    default:
      if (isOld) {
        if (typeof data?.obj2 == 'string') {
          return data?.obj2
        } else {
          if (Array.isArray(data?.obj1)) {
            return
          } else {
            return data?.obj2?.name || data?.obj2?.id
          }
        }
      } else {
        if (typeof data?.obj1 == 'string') {
          return data?.obj1
        } else {
          if (Array.isArray(data?.obj1)) {
            return
          } else {
            return data?.obj1?.name || data?.obj1?.id
          }
        }
      }
  }
}

const ShowDifference = ({ data, original_data, updated_data, onExpand, difference, parentPageName, fields }) => {
  console.log('updated log:::::::::', original_data, updated_data)
  return (
    <div>
      <div className='permission-table'>
        <div className='mb-2 cursor-pointer' onClick={() => onExpand()}>
          <i class='fa-sharp fa-solid fa-arrow-left  m-3'></i>
          Go Back
        </div>
        <div className={`permission-header`} style={{ color: primary_color }}>
          Details
        </div>
        <div className='p-4'>
          {fields?.map((item) => {
            if (checkIsFieldHide(item?.field_id)) return
            return (
              <div className='d-flex mb-2 align-items-center' style={{ width: '100%' }}>
                <div className='font-weight-bold mb-2' style={{ width: '40%' }}>
                  {item?.label}:
                </div>
                <div style={{ width: '30%' }}>{getValueByFieldTypeNid(updated_data, item?.type, item?.field_id)}</div>
                {checkIsDataUpdated(original_data, updated_data, item?.field_id) &&
                  getValueByFieldTypeNid(original_data, item?.type, item?.field_id) && (
                    <div
                      style={{ width: '30%' }}
                      className={`${checkIsDataUpdated(original_data, updated_data, item?.field_id) ? 'p-2 bg-danger text-light' : ''}`}>
                      {getValueByFieldTypeNid(original_data, item?.type, item?.field_id)}
                    </div>
                  )}
              </div>
            )
          })}
          {/* {Object.keys(difference)?.map((key) => {
            return (
              <div className='d-flex mb-2' style={{ width: '100%' }}>
                <div className='font-weight-bold' style={{ width: '40%' }}>
                  {tidyName(key)}
                </div>
                <div>{getDifferenceDataByKey(key, difference[key])}</div>
              </div>
            )
          })} */}
        </div>
      </div>
    </div>
  )
}

const UpdateLog = ({ data, onExpand, expandedId, parentPageName }) => {
  let updated_data = data?.updated_data && JSON.parse(data?.updated_data)
  let original_data = data?.original_data && JSON.parse(data?.original_data)
  let tab_name = getTabNameForLogs(parentPageName, data?.tab_name)
  const fields = getFieldsAndLabelByTab(parentPageName, data?.tab_name)

  // const differenceAll = getObjectDifference(updated_data, original_data)

  // const { updated_at, role_id, full_name, user, ...difference } = differenceAll
  // const updatedDataKeys = Object.keys(difference)

  // if (updatedDataKeys?.length == 0) return

  if (expandedId) {
    return <ShowDifference data={data} updated_data={updated_data} original_data={original_data} fields={fields} onExpand={onExpand}></ShowDifference>
  }
  if (!checkIsDataUpdatedInLog(original_data, updated_data, fields)) return
  return (
    <div className='d-flex align-items-center p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
      <div>
        <i
          class='fa-solid fa-arrow-up-right-from-square mr-2 cursor-pointer'
          style={{ color: primary_color }}
          onClick={() => {
            onExpand(data?.id)
          }}></i>
      </div>
      <div style={{ flex: 1 }}>
        <span className='font-weight-bold'>{data?.updated_by}</span>
        {` has updated data in `}
        <span className='font-weight-bold'>{tab_name}</span>
      </div>
      {data?.updated_at && <div>{moment(data?.updated_at).format('MMM D  YYYY, hh:mm')}</div>}
    </div>
  )
}

export default UpdateLog
