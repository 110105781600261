import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { request } from '../../Redux/Sagas/requests/api'

const fetchLogsByTable = async (id, table) => {
  const response = await request(`/get-log-data`, 'POST', { source_table: table,  })
  return response.data
}

export const useFetchPurchaseLog = (id, table) => {
  const dispatch = useDispatch()

  const query = useQuery(['purchase-log', id, table], () => fetchLogsByTable(id, table), {
    onSuccess: (data) => {},
  })

  return {
    data: query.data,
    error: query.error,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
  }
}
