import PublicFooter from './Footer/footer'
import PublicHeader from './Header/header'

const PublicPageWrapper = ({ children }) => {
  return (
    <>
      <PublicHeader />
      {children}
      <PublicFooter />
    </>
  )
}

export default PublicPageWrapper
