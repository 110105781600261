import React, { useEffect, useRef, useState } from 'react'
import { dummyPaymentDetail } from '../../../CMSComponent/PageVariation/PublicPage/dummyPage'
import { cms } from '../../../helper'
import { getLocalStorageItem } from '../../../Helper/cookieHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { primary_color } from '../../../Helper/uiHelper'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import CMSWidgetModal from '../../CMSWidget/cmsWidgetModal'
import CMSHeroSearch from '../../CMSWidget/HeroSearch/cmsHeroSearch'
import CMSPaymentDetails from '../../CMSWidget/PaymentDetails/cmsPaymentDetails'

const getPaymentData = (data) => {
  const paymentDetails = [
    { name: 'Sub total: ', value: `${data?.['amount']}` },
    { name: 'Discount: ', value: `${data?.['total_coupon_discount']}` },
    { name: 'Fees/charges: ', value: `${data?.['charge']}` },
    { name: 'Total Price: ', value: `${data?.['total']}` },
  ]

  return paymentDetails
}
const Payment = ({ data, parentData, couponCode }) => {
  const [paymentDetails, setPaymentDetails] = useState()
  const isDesktop = useMediaQuery('(min-width:768px)')

  const sectionRef = useRef(null)

  const [modalPosition, setModalPosition] = useState()

  const handleSectionClick = () => {
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect()
      setModalPosition({
        //  top: rect.top + window.scrollY - 50,
        top: rect.height + 100,

        //  left: `${rect.left + window.scrollX + rect.width / 2 - 10}px` , // Position below the parent element
        //  left: rect.left + window.scrollX, // Align left edge with parent element
        width: '60vw',
      })
    }
  }

  let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
  let current_storage_timer = storage_timer?.[parentData?.id]

  useEffect(() => {
    if (!current_storage_timer?.session_uuid) return
    request('get-payment', 'POST', { uuid: current_storage_timer?.session_uuid, coupon_code: couponCode }).then((res) => {
      setPaymentDetails(res?.data)
    })
  }, [couponCode, parentData?.id])

  if (!paymentDetails) return

  const details = getPaymentData(paymentDetails)

  const onCloseModal = () => {
    let element = sectionRef?.current
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' })
    }
    setModalPosition(null)
  }
  return (
    <div
      className={`col-md-12 ml-auto mr-auto ${modalPosition && 'active-cms-edit'}`}
      style={{ marginTop: '2rem', paddingRight: isDesktop ? '0' : '2rem' }}
      onClick={handleSectionClick}
      ref={sectionRef}>
      <div class='border-bottom pb-4 ' style={{ marginLeft: 'auto' }}>
        <div class='mb-3'>
          <div class='h4 font-weight-bold mb-3 body1' style={{ color: primary_color }}>
            {data?.heading ? cms(data?.heading) : 'Payment'}
          </div>
          <div>
            {details?.map((element, index) => {
              return (
                <div
                  className={`d-flex justify-content-between mb-3 ${isDesktop ? '' : 'w-100'}`}
                  style={isDesktop ? { maxWidth: '16rem' } : {}}
                  key={index}>
                  <div className='subtitle1' style={index === details.length - 1 ? { color: primary_color } : {}}>
                    {element.name}
                  </div>
                  <div className='font-weight-bold subtitle1' style={index === details.length - 1 ? { color: primary_color, fontSize: '20px' } : {}}>
                    {element.value}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {modalPosition && (
        <CMSWidgetModal sx={{ ...modalPosition }} toggleModal={() => onCloseModal()}>
          <CMSPaymentDetails
            data={data}
            toggleModal={() => {
              onCloseModal()
            }}
          />
        </CMSWidgetModal>
      )}
    </div>
  )
}

export default Payment
