import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useFetchPurchaseLog } from '../../Queries/Logs/useFetchPurchaseLog'
import PurchaseCreateLog from './purchaseCreateLog'


const renderLog = (type, data, onExpand, expandedId) => {
  switch (type) {
    case 'create':
      return <PurchaseCreateLog data={data} onExpand={onExpand} expandedId={expandedId}></PurchaseCreateLog>

    default:
      return
  }
}

const PurchaseLog = () => {
  const { data } = useFetchPurchaseLog(null, 'payments')

  const [expandedId, setExpandedId] = useState()

  console.log('data::::::::::::',data)
  const onExpand = (id) => {

    setExpandedId(id)
  }

  return (
    <div>
      {data?.data?.map((item, index) => {
        if (!expandedId) {
          return (
            <div key={index} className='p-3' style={{ borderBottom: '1px solid #e0e0e0' }}>
              {renderLog(item?.type, item, onExpand, null)}
            </div>
          )
        } else {
          if (expandedId === item?.id) {
            return (
              <div key={index} className='p-3'>
                {renderLog(item?.type, item, onExpand, expandedId)}
              </div>
            )
          } else {
            return <></>
          }
        }
      })}
    </div>
  )
}

export default PurchaseLog
